import { comparer, computed, observable, reaction, makeObservable } from 'mobx';
import { ProductRef } from '@teliaee/sf.sdk.common';
import { Api } from '../../service/Api';
import SsoStore from '../sso/SsoStore';
import FeatureStore from '../feature/FeatureStore';
import ErrorStore from '../error/ErrorStore';
import MandateCheckerStore from '../mandateChecker/MandateCheckerStore';
import ProductUserCompact from '../../model/product/productUserCompact/ProductUserCompact';

export default class ProductUserStore {
  @observable readonly productUsersRequest = Api.productUsers();

  constructor(
    private rootStore: {
      ssoStore: SsoStore;
      featureStore: FeatureStore;
      errorStore: ErrorStore;
      mandateCheckerStore: MandateCheckerStore;
    }
  ) {
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(() => this.rootStore.ssoStore.person, this.updatePersonProductUsers, {
      name: 'get person when customer selected  -> update person product users',
      equals: comparer.structural,
    });
  }

  async init(): Promise<void> {
    await this.updatePersonProductUsers();
  }

  @computed
  get hasLoadedPersonProductUsers() {
    return this.productUsersRequest.isLoaded;
  }

  @computed
  get displayableProductUsers(): ProductUserCompact[] {
    return this.personProductUsers.concat(this.customerProductUsers);
  }

  @computed
  get hasDisplayableProductUsers(): boolean {
    return !!this.displayableProductUsers.length;
  }

  @computed
  get hasPersonProductUsers(): boolean {
    return !!this.personProductUsers.length;
  }

  @computed
  get hasCustomerProductUsers(): boolean {
    return !!this.customerProductUsers.length;
  }

  @computed
  private get personProductUsers(): ProductUserCompact[] {
    const { customerId } = this.rootStore.ssoStore;
    const { hasLegalOrServiceMandate } = this.rootStore.mandateCheckerStore;
    return hasLegalOrServiceMandate ? [] : this.currentProductUsers.filter((productUser) => productUser.customerId === customerId);
  }

  @computed
  private get customerProductUsers(): ProductUserCompact[] {
    const { customerId, isCustomerSameAsPerson } = this.rootStore.ssoStore;
    return isCustomerSameAsPerson ? this.currentProductUsers.filter((productUser) => productUser.customerId !== customerId) : [];
  }

  @computed
  get currentProductUsers(): ProductUserCompact[] {
    const { isLoaded, data } = this.productUsersRequest;

    if (!isLoaded || !data) {
      return [];
    }
    return data.map((resource) => new ProductUserCompact(resource));
  }

  private updatePersonProductUsers = async (): Promise<void> => {
    this.productUsersRequest.clear();

    const { person } = this.rootStore.ssoStore;
    if (!person || !person.personalCode || !person.personalCodeCountryCode) {
      return;
    }

    await this.productUsersRequest.load();

    if (this.productUsersRequest.isErrored) {
      this.rootStore.errorStore.setGeneralTechnicalError(new Error('Person product users fetch failed'));
    }
  };

  getProductUser(productRef: ProductRef): ProductUserCompact | undefined {
    return this.currentProductUsers.find((productUser) => productUser.productIdAndOrigin === productRef.productIdAndOrigin);
  }
}
