import { observable, action, computed, toJS, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig } from 'axios';
import { API, ApiResponse, ErrorUtil, isApiResponse, isApiResponseWithError, HttpStatusCode } from '@teliaee/sf.core';
import { URI } from 'uri-template-lite';
import { ApiUrl } from '../model/ApiUrl';
import { POLLING_INTERVAL_MS } from '../util/constants';

export const USER_CANCELLED_REQUEST = 'USER_CANCELLED_REQUEST';
export const MAX_RETRIES = 10;

export interface ApiRequestConfig extends Omit<AxiosRequestConfig, 'url'> {
  urlParams?: {};
  poll?: boolean;
}

export class ApiRequest<T extends any = any> {
  @observable isLoading = false;
  @observable private httpErrors: Error[] = [];
  @observable.ref private response: ApiResponse<T | undefined> | undefined;
  private config: ApiRequestConfig = {};
  private cancelTokenSource = axios.CancelToken.source();
  private pollTimeout: number;
  private url: URI.Template;

  constructor(url: ApiUrl, config: ApiRequestConfig = {}) {
    makeObservable(this);
    this.url = new URI.Template(url);
    this.config = config;
  }

  async load(config?: ApiRequestConfig): Promise<this> {
    this.cancel();
    this.patchConfig(config);
    this.setLoading(true);

    try {
      const response = await this.makeRequest();
      this.schedulePollIfApplicable();
      this.setResponse(response, undefined);
    } catch (error) {
      /* istanbul ignore next */
      if (error.message !== USER_CANCELLED_REQUEST && error.response?.status !== HttpStatusCode.SESSION_EXPIRED) {
        ErrorUtil.pushError(error);
        this.schedulePollIfApplicable();
        this.setResponse(undefined, error);
      }
    } finally {
      this.setLoading(false);
    }

    return this;
  }

  @action
  clear(): void {
    this.cancel();
    this.setResponse(undefined, undefined);
    this.setLoading(false);
  }

  cancel() {
    this.cancelRequest();
    window.clearTimeout(this.pollTimeout);
  }

  @computed
  get isLoaded(): boolean {
    return !!(this.httpErrors.length || this.response);
  }

  @computed
  get isErrored(): boolean {
    return !!(this.httpErrors.length || isApiResponseWithError(this.response));
  }

  @computed
  get apiResponse(): ApiResponse | undefined {
    return this.response;
  }

  @computed
  get data(): T | undefined {
    return this.response?.data;
  }

  set data(data: T | undefined) {
    this.response = { ...this.response, data };
  }

  private schedulePollIfApplicable() {
    if (this.config.poll) {
      this.pollTimeout = window.setTimeout(() => {
        this.load();
      }, POLLING_INTERVAL_MS);
    }
  }

  private patchConfig(config?: ApiRequestConfig) {
    this.config = { ...this.config, ...config };
  }

  @action
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  private setResponse(response: ApiResponse<T> | undefined, httpError: Error | undefined) {
    this.response = response;
    this.httpErrors = httpError ? this.httpErrors.concat(httpError) : [];
    /* istanbul ignore next */
    if (this.httpErrors.length >= MAX_RETRIES) {
      this.cancel();
      ErrorUtil.pushError(new Error(`${MAX_RETRIES} consecutive failed attempts to poll ${this.axiosRequestConfig.url}`));
    }
  }

  private get axiosRequestConfig() {
    const { urlParams, poll, params, ...rest } = this.config;

    return {
      ...rest,
      url: this.url.expand(urlParams || {}),
      params: toJS(params),
      cancelToken: this.cancelTokenSource.token,
    };
  }

  private async makeRequest(): Promise<ApiResponse<T>> {
    const { data: responseBody } = await API.request<ApiResponse<T>>(this.axiosRequestConfig);
    /* istanbul ignore next */
    return isApiResponse(responseBody) ? responseBody : { data: responseBody };
  }

  private cancelRequest(): void {
    this.cancelTokenSource.cancel(USER_CANCELLED_REQUEST);
    this.cancelTokenSource = axios.CancelToken.source();
  }
}
