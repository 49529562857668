// TODO: temp "fix" Origin along with other common logic should be moved to myse-frontend anyway
export enum Origin {
  ORDERAPI = 'ORDERAPI',
  ARGOS = 'ARGOS',
  ARGOS_SERV = 'ARGOS_SERV',
  TBCIS = 'TBCIS',
  TBCIS_MAAS = 'TBCIS_MAAS',
  TBCIS_SERV = 'TBCIS_SERV',
  ELIS = 'ELIS',
}

export class GlobalProductIdUtil {
  private static readonly UNDERSCORE: string = '_';
  private static readonly BEGINNING_INDEX: number = 0;
  private static readonly NEXT_SYMBOL_INDEX: number = 1;

  static getOrigin(globalProductId: string): Origin {
    return Origin[globalProductId.split(GlobalProductIdUtil.UNDERSCORE)[GlobalProductIdUtil.NEXT_SYMBOL_INDEX]];
  }

  static getProductId(globalProductId: string): number {
    return +globalProductId.substring(GlobalProductIdUtil.BEGINNING_INDEX, globalProductId.indexOf(GlobalProductIdUtil.UNDERSCORE));
  }

  static getGlobalProductId(productId: number, origin: Origin): string {
    return `${productId}${GlobalProductIdUtil.UNDERSCORE}${origin}`;
  }
}
