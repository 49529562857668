import { CharacteristicCode } from '@teliaee/sf.sdk.common';
import { ProductAllowedAction, CustomerProductCharacteristicResource, NoticeResource } from '@teliaee/sf.sdk.customer-products';
import { isTemplate, Template } from './Template';

export default class CustomerProductCharacteristic<T = any> {
  code: CharacteristicCode;
  value: T;
  valueLabel: string;
  icon: string;
  orderBy: number;
  template: Template;
  notice: NoticeResource;
  allowedActions: ProductAllowedAction[] = [];

  constructor(data?: CustomerProductCharacteristicResource) {
    Object.assign(this, data);
    this.allowedActions =
      data && data.allowedActions ? data.allowedActions.map<ProductAllowedAction>((action) => new ProductAllowedAction(action)) : [];
    this.template = data && data.template && isTemplate(data.template) ? data.template : Template.CHARACTERISTIC_DEFAULT;
  }

  get isInactive(): boolean {
    return this.code === CharacteristicCode.NEW_SPEED;
  }

  get isDisplayable(): boolean {
    return this.template !== Template.NO_DISPLAY;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CharacteristicValue {
  export enum STRICT {
    SPEED_LIMIT_APPLIED = 'Y',
    USAGE_BLOCKED = 'B',
    EXTRA_CHARGE_IMPOSED = 'N',
    UNLIMITED = 'U',
  }
  export enum SHOW_ON_INVOICE {
    SHOW = 'Y',
    DO_NOT_SHOW = 'N',
  }
  export enum NETWORK_OWNER {
    ELEKTRILEVI = '1',
  }
  export enum TECHNOLOGY {
    NETWORK_5G = '5GNR',
    LTE = 'LTE',
  }
}
