import { action, computed, observable, autorun, makeObservable } from 'mobx';
import SsoClient, { SsoOptions } from 'telia-sso-client/lib/sso-client';
import { AxiosError } from 'axios';
import { CoreConfig, HttpStatusCode, interceptResponse } from '@teliaee/sf.core';
import {
  SsoClientState,
  SsoAuthLevel,
  SsoClientCustomer,
  SsoClientPerson,
  SsoClientDealer,
  CustomerDetailKeys,
  SsoClientAuth,
} from '@teliaee/sf.service.common';
import { sendPostMessageToWebView } from '@teliaee/sf.ui.common';
import ErrorStore from '../error/ErrorStore';
import LocaleStore from '../locale/LocaleStore';
import CrmStore from '../crm/CrmStore';
import NavigationStore from '../navigation/NavigationStore';
import MandateCheckerStore from '../mandateChecker/MandateCheckerStore';
import { CustomerContract, CustomerContractState } from '../../model/crm/CustomerContract';
import CrmApi from '../../service/crm/CrmApi';

const BUSINESS_CUSTOMER_TYPE = 'B2B';
const PRIVATE_CUSTOMER_TYPE = 'B2C';

export default class SsoStore {
  @observable loaded = false;
  @observable loadIsSlow = false;
  @observable state: SsoClientState = {};
  @observable isSignInInvoked = false;
  @observable isCustomerSelectedAutomatically = false;
  @observable customerContracts: CustomerContract[] | undefined;

  private sso = SsoClient.connect(
    {
      env: CoreConfig.environment === 'local' ? 'dev' : CoreConfig.environment,
      onLogout: () => this.handleLogout(),
      onLogin: this.handleSsoLoaded.bind(this),
    },
    {
      app: 'telia',
    }
  );

  /* istanbul ignore next 13 */
  static get ssoClientUrl() {
    switch (CoreConfig.environment) {
      case 'local':
      case 'dev':
        return 'https://sso-arendus.telia.ee';
      case 'test':
        return 'https://sso-test.telia.ee';
      case 'live':
        return 'https://sso.telia.ee';
      default:
        throw new Error(`Unexpected environment “${CoreConfig.environment}”`);
    }
  }

  constructor(
    private rootStore: {
      errorStore: ErrorStore;
      localeStore: LocaleStore;
      crmStore: CrmStore;
      navigationStore: NavigationStore;
      mandateCheckerStore: MandateCheckerStore;
    }
  ) {
    makeObservable(this);
  }

  init(): void {
    CoreConfig.isWebview && this.proxySsoClientPostMessagesToWebView();

    this.sso.loadInitialState();
    setTimeout(this.setLoadIsSlow, 5000);

    interceptResponse(
      (response) => response,
      (error) => this.handleApiError(error)
    );
  }

  @action
  reload(isCustomerSelectedAutomatically = false): void {
    this.sso.loadInitialState();
    this.isCustomerSelectedAutomatically = isCustomerSelectedAutomatically;
  }

  @computed
  get authLevel(): SsoAuthLevel | undefined {
    return this.state.auth?.authLevel;
  }

  @computed
  get isLoggedInWithHardAuth(): boolean {
    return this.authLevel === 2 || !!this.dealer;
  }

  @computed
  get mandates() {
    return this.state.auth?.mandates?.filter((mandateOrProductUserPermit) => mandateOrProductUserPermit.mandate !== 'USER');
  }

  @computed
  get customer(): SsoClientCustomer | undefined {
    return this.state.auth && this.state.auth.customer;
  }

  @computed
  get person(): SsoClientPerson | undefined {
    return this.state.auth && this.state.auth.person;
  }

  @computed
  get dealer(): SsoClientDealer | undefined {
    return this.state.auth && this.state.auth.dealer;
  }

  @computed
  get isCustomerSelected(): boolean {
    return !!this.customer;
  }

  @computed
  get customerId(): number | undefined {
    return this.customer && this.customer.customerId;
  }

  @computed
  get customerSegment(): string | undefined {
    return this.customer && this.customer.type;
  }

  @computed
  get contextOrCustomerSegment(): string | undefined {
    return this.rootStore.navigationStore.isBusinessContext ? BUSINESS_CUSTOMER_TYPE : this.customerSegment;
  }

  @computed
  get isDealer(): boolean {
    return !!this.dealer;
  }

  @computed
  get isPrivateCustomer(): boolean {
    return !!this.customerSegment && this.customerSegment === PRIVATE_CUSTOMER_TYPE;
  }

  @computed
  get isBusinessCustomer(): boolean {
    return !!this.customerSegment && this.customerSegment === BUSINESS_CUSTOMER_TYPE;
  }

  @computed
  get isCustomerSameAsPerson(): boolean {
    if (!this.person || !this.customer) {
      return false;
    }

    const { personalCode, personalCodeCountryCode } = this.person;
    const { registrationNumber, registrationNumberCountryCode } = this.customer;

    return personalCode === registrationNumber && personalCodeCountryCode === registrationNumberCountryCode;
  }

  @computed
  get ssoClient() {
    return this.sso;
  }

  @computed
  get ssoModalProps(): SsoOptions | undefined {
    if (this.isSignInInvoked) {
      return {
        selectMandate: true,
        authLevel: this.isLoggedInWithHardAuth ? undefined : 2,
        locale: this.rootStore.localeStore.currentLocale,
      };
    }
    return undefined;
  }

  hasCustomer = (...customerDetails: CustomerDetailKeys) => {
    if (!this.customer) {
      return false;
    }

    for (const detail of customerDetails) {
      if (detail === 'contract' && !this.customerContracts?.length) {
        return false;
      }
      if (detail !== 'contract' && !this.customer[detail]) {
        return false;
      }
    }

    return true;
  };

  hasDealerRole(role: string): boolean {
    return !!this.dealer && this.dealer.roles.includes(role);
  }

  ensureHardAuthentication = (): boolean => {
    if (this.isLoggedInWithHardAuth && this.isCustomerSelected) {
      return true;
    }

    this.invokeSignIn();

    return false;
  };

  ensureSignIn = (isSufficientlyAuthenticated: () => boolean) => {
    const { toggleSignIn } = this;
    const reactions = [autorun(() => toggleSignIn(!isSufficientlyAuthenticated()))];
    return () => reactions.forEach((dispose) => dispose());
  };

  invokeSignIn = () => this.setIsSignInInvoked(true);

  dismissSignIn = () => this.setIsSignInInvoked(false);

  toggleSignIn = (invoke: boolean) => (invoke ? this.invokeSignIn() : this.dismissSignIn());

  private async handleSsoLoaded(state: SsoClientState): Promise<void> {
    await this.redirectToRegistrationIfNecessary(state.auth);
    const { crmStore, mandateCheckerStore } = this.rootStore;
    this.reset(state);

    if (!!this.customerId) {
      if (this.person && this.person.personalCode && this.mandates?.length) {
        crmStore.setupContactInfo();
      }
    }
    if (mandateCheckerStore.hasLegalMandate || mandateCheckerStore.hasServiceMandate) {
      crmStore.fetchCustomerCharacteristics();
    }
  }

  @action
  private reset(state: SsoClientState) {
    this.rootStore.crmStore.reset();
    this.setState(state);
    this.setLoaded(true);
    this.setIsSignInInvoked(false);
  }

  private handleLogout = (): void => {
    window.onbeforeunload = null;
    this.rootStore.navigationStore.redirectToPublicFrontPage();
  };

  private async redirectToRegistrationIfNecessary(auth: SsoClientAuth | undefined): Promise<void> {
    if ((await this.isRegistrationRequired(auth)) || (auth?.customer && auth!.customer!.customerId === undefined)) {
      const { pathname, search } = this.rootStore.navigationStore.location;
      const registrationPath = `${
        CoreConfig.selfServiceUrl + NavigationStore.REGISTER + '?goToUrl=' + encodeURIComponent(pathname + search)
      }`;

      this.rootStore.navigationStore.navigateTo(registrationPath);
    }

    if (auth?.dealer && this.rootStore.navigationStore.location.pathname.includes(NavigationStore.TECHNICIAN)) {
      return;
    }

    if (auth?.dealer && auth?.customer === undefined) {
      this.rootStore.navigationStore.navigateTo(CoreConfig.selfServiceUrl + NavigationStore.CUSTOMER_SEARCH);
    }
  }

  private async isRegistrationRequired(auth: SsoClientAuth | undefined): Promise<boolean> {
    if (!auth || !auth.customer || auth.dealer || !auth?.mandates?.find(({ mandate }) => mandate === 'LEGAL')) {
      return false;
    }

    if (!auth.customer.customerId) {
      return true;
    }
    const contracts = await this.fetchCustomerContracts(auth.customer.customerId, 'SIGNED');
    this.setCustomerContracts(contracts);

    return !contracts || contracts.length === 0;
  }

  @action
  private setCustomerContracts(customerContracts: CustomerContract[] | undefined) {
    this.customerContracts = customerContracts;
  }

  async fetchCustomerContracts(customerId: number, stateCode: CustomerContractState): Promise<CustomerContract[]> {
    try {
      return await CrmApi.getCustomerContracts(customerId, stateCode);
    } catch (error) {
      error.message = 'Customer contracts fetch failed: ' + error.message;
      this.rootStore.errorStore.setGeneralTechnicalError(error);
      throw error;
    }
  }

  private handleApiError = (error: AxiosError) => {
    if (error.response && error.response.status === HttpStatusCode.SESSION_EXPIRED && !!this.person) {
      this.sso.doLogout();
    }
    return Promise.reject(error);
  };

  private proxySsoClientPostMessagesToWebView() {
    window.addEventListener('message', (event) => {
      if (event.origin !== SsoStore.ssoClientUrl || !window.ReactNativeWebView) {
        return;
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(event.data.data));
    });
  }

  @action
  private setLoadIsSlow = (): void => {
    if (!this.loaded) {
      this.loadIsSlow = true;
    }
  };

  @action
  private setIsSignInInvoked(isSignInInvoked: boolean): void {
    this.isSignInInvoked = isSignInInvoked;
    if (this.isSignInInvoked) {
      sendPostMessageToWebView({
        type: 'showSsoLogin',
        payload: {
          authLevel: this.ssoModalProps?.authLevel,
        },
      });
    }
  }

  @action
  private setState = (state: SsoClientState): void => {
    this.state = state;
  };

  @action
  private setLoaded(loaded: boolean): void {
    this.loaded = loaded;
  }
}
