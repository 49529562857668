import { action, observable, reaction, makeObservable, computed } from 'mobx';

import { API, CoreConfig, ParamsUtil, PlaceholderMapping } from '@teliaee/sf.core';
import { LabelNamespace } from '@teliaee/sf.sdk.common';
import ErrorStore from '../error/ErrorStore';
import LocaleStore, { Locale } from '../locale/LocaleStore';

interface TranslationMapping {
  [key: string]: TranslationMapping | string;
}

export const BASE_URL = '/myse-frontend/api';

class TranslateStore {
  @observable translations: TranslationMapping = {};

  private localeStore: LocaleStore;
  private errorStore: ErrorStore;
  private loaded = false;
  private DEBUG = false;
  private realTranslations: TranslationMapping;

  // recursively convert translation keys to lowercase for case-insensitivity
  static keysToLowerCase(translations: TranslationMapping): TranslationMapping {
    const lowerCasedTranslations = {};

    if (!translations) {
      return lowerCasedTranslations;
    }

    for (const key in translations) {
      /* istanbul ignore else */
      if (translations.hasOwnProperty(key)) {
        lowerCasedTranslations[key.toLowerCase()] =
          {}.toString.call(translations[key]) === '[object Object]'
            ? this.keysToLowerCase(translations[key] as TranslationMapping)
            : translations[key];
      }
    }

    return lowerCasedTranslations;
  }

  static toDebugValue(key: string) {
    return `KEY: ${key}`;
  }

  constructor(stores: { localeStore: LocaleStore; errorStore: ErrorStore }) {
    makeObservable(this);
    this.localeStore = stores.localeStore;
    this.errorStore = stores.errorStore;
    this.addListener();
  }

  private addListener() {
    // This logic is only for debugging purposes in dev and test env.
    if (CoreConfig.environment !== 'live') {
      window.addEventListener('keydown', this.getListener);
    }
  }

  getListener = (event: KeyboardEvent) => {
    /* istanbul ignore next */
    if (event.ctrlKey && event.key === 'm') {
      if (!this.DEBUG) {
        this.setTranslations(this.translationsToKeys(this.translations));
      } else {
        this.setTranslations(this.realTranslations);
      }
      this.DEBUG = !this.DEBUG;
    }
  };

  translationsToKeys = (translations: TranslationMapping): TranslationMapping => {
    const convertedTranslations = {};
    for (const key in translations) {
      /* istanbul ignore next */
      if (translations.hasOwnProperty(key)) {
        convertedTranslations[key.toLowerCase()] =
          {}.toString.call(translations[key]) === '[object Object]'
            ? this.translationsToKeys(translations[key] as TranslationMapping)
            : TranslateStore.toDebugValue(key);
      }
    }
    return convertedTranslations;
  };

  async init() {
    reaction(() => this.localeStore.currentLocale, this.updateTranslations, {
      name: 'locale changed -> update translations',
    });

    await this.updateTranslations(this.localeStore.currentLocale);
  }

  private updateTranslations = async (locale: Locale): Promise<void> => {
    try {
      const response = await API.get<{ translations: TranslationMapping }>(`${BASE_URL}/v1/translations/${locale}`);
      this.loaded = true;
      this.realTranslations = response.data.translations;
      this.setTranslations(response.data.translations);
    } catch (error) {
      error.message = 'Failed to fetch translations: ' + error.message;
      this.errorStore.setGeneralTechnicalError(error);
    }
  };

  @action
  private setTranslations = (translations: TranslationMapping) => {
    this.translations = translations;
  };

  translateHtml = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = true) => ({
    __html: this.translate(keys, params, sanitizeParams),
  });

  translateGlobalHtml = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = true) => ({
    __html: this.translateGlobal(keys, params, sanitizeParams),
  });

  translateProductcatalogHtml = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = true) => ({
    __html: this.translateProductcatalog(keys, params, sanitizeParams),
  });

  translateNamespaces = (
    namespaces: LabelNamespace[],
    keys: string | string[],
    params?: PlaceholderMapping,
    sanitizeParams = false
  ): string => {
    const keysString = Array.isArray(keys) ? keys.join() : keys;

    for (const namespace of namespaces) {
      const translation = this.getTranslationWithOptions(namespace, keys, params, sanitizeParams);

      if (translation !== keysString) {
        return translation;
      }
    }

    return keysString;
  };

  translate = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = false): string =>
    this.getTranslationWithOptions(LabelNamespace.salesfront, keys, params, sanitizeParams);

  translateGlobal = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = false): string =>
    this.getTranslationWithOptions(LabelNamespace.global, keys, params, sanitizeParams);

  translateProductcatalog = (keys: string | string[], params?: PlaceholderMapping, sanitizeParams = false): string =>
    this.getTranslationWithOptions(LabelNamespace.productcatalog, keys, params, sanitizeParams);

  hasTranslation = (keys: string | string[]): boolean =>
    this.translate(keys, undefined, false) !== (keys instanceof Array ? keys : [keys]).join();

  hasProductcatalogTranslation = (keys: string | string[]): boolean =>
    this.translateProductcatalog(keys, undefined, false) !== (keys instanceof Array ? keys : [keys]).join();

  private getTranslationWithOptions = (
    group: string,
    keysObject: string | string[],
    params?: PlaceholderMapping,
    sanitizeParams = true
  ): string => {
    const keys = keysObject instanceof Array ? keysObject : [keysObject];

    for (const key of keys) {
      const translation = this.getTranslation(group, key, params, sanitizeParams);

      if (typeof translation === 'string') {
        return translation;
      }
    }

    return this.loaded ? keys.join() : '';
  };

  private getTranslation = (group: string, key: string, params?: PlaceholderMapping, sanitizeParams = true): string => {
    key = key.toLowerCase();
    group = group.toLowerCase();

    const groupTranslations = this.translationsWithLowerCaseKeys[group];
    const textValue = groupTranslations && groupTranslations[key];

    if (textValue && params) {
      return ParamsUtil.parseParams(textValue, params, sanitizeParams);
    }

    return textValue;
  };

  @computed
  private get translationsWithLowerCaseKeys(): TranslationMapping {
    return TranslateStore.keysToLowerCase(this.translations);
  }
}

export default TranslateStore;
