import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route as RouterRoute, Router, Switch, useRouteMatch } from 'react-router-dom';
import { AnalyticsProvider, Overlay } from 'telia-front-react';
import { Analytics, Environment } from '@teliaee/sf.core';
import { Head, NotFound } from '@teliaee/sf.ui.common';
import { CustomerProductsContext, CustomerProductsContextProvider } from './CustomerProductsContext';
import { Route } from '../../shared/routes/Route';
import { useUiBlocking } from '../../shared/components/uiBlocking/hooks/useUiBlocking';
import RootStoreContext from '../../shared/store/root/RootStoreContext';
import { CustomerProductsPubSubConnectionStore } from '../../shared/store/customerProductsPubSubConnection/CustomerProductsPubSubConnectionStore';

export interface CustomerProductsRootProps {
  environment: Environment;
  routes: Route[];
}

export const CustomerProductsRoot: React.FC<CustomerProductsRootProps> = observer(({ environment, routes }) => {
  const commonContext = useContext(RootStoreContext);
  const [customerProductsContext, setCustomerProductsContext] = useState<CustomerProductsContext>();
  const uiBlocking = useUiBlocking();

  useEffect(() => {
    setCustomerProductsContext({
      environment,
      pubSubConnectionStore: new CustomerProductsPubSubConnectionStore(environment, commonContext),
      uiBlocking,
    });
  }, []);

  if (!customerProductsContext) {
    return null;
  }

  if (commonContext.featureStore.isEnabled('ENABLE_WEBSOCKET')) {
    if (!customerProductsContext.pubSubConnectionStore.isConnected) {
      return <Overlay spinner />;
    }
  }

  return (
    <CustomerProductsContextProvider value={customerProductsContext}>
      <Router history={commonContext.navigationStore.history}>
        {uiBlocking.isBlocked && <Overlay spinner />}
        <Switch>
          {routes.map((route) => (
            <RouterRoute exact key={route.key} path={route.path}>
              <AnalyticsProvider
                event={Analytics.defaultEvent}
                page={Analytics.join(Analytics.appName, route.key)}
                group={Analytics.defaultCategory}
                onPush={Analytics.push}
              >
                <RouteComponent route={route} />
              </AnalyticsProvider>
            </RouterRoute>
          ))}
        </Switch>
      </Router>
    </CustomerProductsContextProvider>
  );
});

const RouteComponent: React.FC<{ route: Route }> = observer((props) => {
  const match = useRouteMatch();
  const { route } = props;

  if (!route.validate(match)) {
    return <NotFound />;
  }

  return (
    <>
      <Head {...route.head} />
      <route.component match={match} />
    </>
  );
});
RouteComponent.displayName = 'RouteComponent';
