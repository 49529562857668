import { computed, reaction, comparer, makeObservable } from 'mobx';
import OaCrmWebapi from 'telia-eventapi-client';
import { CoreConfig } from '@teliaee/sf.core';
import NavigationStore from '../navigation/NavigationStore';
import SsoStore from '../sso/SsoStore';
import RouteKey from '../../model/navigation/RouteKey';

export enum OnlineHelpNamespace {
  MY_SERVICES = 'my_services',
}

export enum OnlineHelpEvent {
  VIEW_HOME_SERVICES = 'INFO_MY_SERVICES_VIEW_HOME',
  VIEW_VAS_SERVICES = 'INFO_MY_SERVICES_VIEW_VAS',
  VIEW_MOBILE_SERVICES = 'INFO_MY_SERVICES_VIEW_MOBILE',
  VIEW_OFFICE_SERVICES = 'NFO_MY_SERVICES_VIEW_OFFICE',
  VIEW_MOBILE_ADDITIONAL_SERVICES = 'INFO_MY_SERVICES_VIEW_MOBILE_ADDITIONAL',
  VIEW_DASHBOARD_SERVICES = 'INFO_MY_SERVICES_DASHBOARD',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  ACTION_SERVICE_PREFIX = 'ACTION_SERVICE_',
  ACTION_SERVICE_ORDER = 'ACTION_SERVICE_ORDER',
  ACTION_DEVICE_PREFIX = 'ACTION_DEVICE_',
  ACTION_DEVICE_ORDER = 'ACTION_DEVICE_ORDER',
  ACTION_SERVICE_TERMINATE_CANCEL = 'ACTION_SERVICE_TERMINATE_CANCEL',
  ACTION_SERVICE_TERMINATE_CONFIRM = 'ACTION_SERVICE_TERMINATE_CONFIRM',
  ACTION_SERVICE_MOVE_CANCEL = 'ACTION_SERVICE_MOVE_CANCEL',
  ACTION_SERVICE_MOVE_CONFIRM = 'ACTION_SERVICE_MOVE_CONFIRM',
  SAVE_SECURE_INTERNET_FILTER_REPORT = 'SAVE_SECURE_INTERNET_FILTER_REPORT',
  DOWNLOAD_CONTRACT = 'DOWNLOAD_CONTRACT',
}

export interface OnlineHelpEventProps {
  namespace?: OnlineHelpNamespace;
  name: string;
  params?: { [index: string]: string };
  content?: { [index: string]: string };
}
type OaCrmWebapis = { [key in OnlineHelpNamespace]: OaCrmWebapi };

export default class OnlineHelpStore {
  static onlineHelpRouteEventMap: { [index: string]: OnlineHelpEvent } = {
    [RouteKey.HOME_PRODUCTS]: OnlineHelpEvent.VIEW_HOME_SERVICES,
    [RouteKey.VAS_PRODUCTS]: OnlineHelpEvent.VIEW_VAS_SERVICES,
    [RouteKey.MOBILE_PRODUCTS]: OnlineHelpEvent.VIEW_MOBILE_SERVICES,
    [RouteKey.OFFICE_PRODUCTS]: OnlineHelpEvent.VIEW_OFFICE_SERVICES,
    [RouteKey.MOBILE_ADDITIONAL_SERVICES]: OnlineHelpEvent.VIEW_MOBILE_ADDITIONAL_SERVICES,
    [RouteKey.DASHBOARD]: OnlineHelpEvent.VIEW_DASHBOARD_SERVICES,
  };

  private oaCrmWebapis: OaCrmWebapis = {} as OaCrmWebapis;
  private reactionDisposer = () => {};

  constructor(
    private rootStore: {
      navigationStore: NavigationStore;
      ssoStore: SsoStore;
    }
  ) {
    makeObservable(this);
    this.rootStore = rootStore;

    Object.values(OnlineHelpNamespace).forEach(
      (namespace) => (this.oaCrmWebapis[namespace] = new OaCrmWebapi(CoreConfig.environment, namespace, () => this.customerId))
    );
  }

  init(): void {
    this.reactionDisposer = reaction(this.getRouteKeyAndCustomerId, this.handleRouteChange, {
      equals: comparer.shallow,
      fireImmediately: true,
    });
  }

  terminate() {
    this.reactionDisposer();
  }

  recordEvent({ namespace, name, params, content }: OnlineHelpEventProps): void {
    /* istanbul ignore else */
    if (this.customerId) {
      this.oaCrmWebapis[namespace || OnlineHelpNamespace.MY_SERVICES].recordEvent(name, params, content);
    }
  }

  @computed
  private get customerId(): string {
    const { customerId } = this.rootStore.ssoStore;
    return customerId ? customerId.toString() : '';
  }

  private getRouteKeyAndCustomerId = () => {
    const { routeKey } = this.rootStore.navigationStore;
    const { customerId } = this.rootStore.ssoStore;
    return { routeKey, customerId };
  };

  private getRouteChangeEventName = (): string => {
    const { routeKey, routeParams } = this.rootStore.navigationStore;
    const productIdAndOrigin = routeParams && routeParams[NavigationStore.PARAM_NAME_PRODUCT_AND_ORIGIN];

    if (productIdAndOrigin && OnlineHelpStore.onlineHelpRouteEventMap[routeKey] && routeKey !== RouteKey.MOBILE_ADDITIONAL_SERVICES) {
      return `${OnlineHelpStore.onlineHelpRouteEventMap[routeKey]}_${OnlineHelpEvent.PRODUCT_DETAILS}`;
    }

    return OnlineHelpStore.onlineHelpRouteEventMap[routeKey];
  };

  private handleRouteChange = (): void => {
    const name = this.getRouteChangeEventName();

    if (name) {
      this.recordEvent({ name });
    }
  };
}
