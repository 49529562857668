import { useContext, useEffect } from 'react';
import { CustomerProductsRouteComponentProps } from './Route';
import { compilePath } from '@teliaee/sf.service.common';
import { RoutePathPattern } from './RoutePathPattern';
import RootStoreContext from '../store/root/RootStoreContext';

export const replaceableProductDetailPaths = [
  '/:lang(et|ru|en)?/teenused/kodu/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  '/:lang(et|ru|en)?/teenused/kontor/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  '/:lang(et|ru|en)?/teenused/lisateenused/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  '/:lang(et|ru|en)?/teenused/mobiil/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
];
export type ReplaceProductDetailPathProps = CustomerProductsRouteComponentProps<{ productIdAndOrigin: string }>;

/**
 * Redirect "/teenused/(mobiil|kodu|kontor|lisateenused)/:productIdAndOrigin" -> "/teenused/:productIdAndOrigin"
 * for backwards compatibility in "Minu Telia" app services webview
 *
 * @deprecated
 */
export const ReplaceProductDetailPath: React.FC<ReplaceProductDetailPathProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { path, params } = props.match;
  const { navigationStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (replaceableProductDetailPaths.includes(path)) {
      const url = compilePath(RoutePathPattern.PRODUCT_DETAILS, params);
      navigationStore.navigateTo(url, undefined, true);
    }
  }, [path]);

  return null;
};
