/* istanbul ignore file */
import React, { ErrorInfo } from 'react';
import { ErrorUtil } from '@teliaee/sf.core';
import { TechnicalError } from './TechnicalError';

export class ErrorBoundary extends React.PureComponent<{}, { hasError: boolean }> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ErrorUtil.pushError(error);
  }

  render() {
    if (this.state.hasError) {
      return <TechnicalError message={'error.backend.general'} />;
    }

    return this.props.children;
  }
}
