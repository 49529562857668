import { Environment } from '@teliaee/sf.core';
import { PubSubConnectionStore, PubSubConnectionStoreContext } from '@teliaee/sf.service.common';
import { getPubSubUrl } from '../../util/getPubSubUrl';

export class CustomerProductsPubSubConnectionStore extends PubSubConnectionStore {
  protected connectionUrl = getPubSubUrl(this.environment);

  constructor(private environment: Environment, context: PubSubConnectionStoreContext) {
    super(context);
  }
}
