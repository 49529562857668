import { MessageStore, ViewPortStore } from '@teliaee/sf.ui.common';
import { CommonStore } from '@teliaee/sf.service.common';
import SsoStore from '../sso/SsoStore';
import LocaleStore from '../locale/LocaleStore';
import TranslateStore from '../translate/TranslateStore';
import CrmStore from '../crm/CrmStore';
import SpinnerStore from '../../components/spinner/SpinnerStore';
import NavigationStore from '../navigation/NavigationStore';
import ErrorStore from '../error/ErrorStore';
import AddressStore from '../address/AddressStore';
import ProductStore from '../product/ProductStore';
import OnlineHelpStore from '../onlineHelp/OnlineHelpStore';
import CookieConsentStore from '../cookieConsent/CookieConsentStore';
import MegamenuStore from '../megamenu/MegamenuStore';
import AgreementStore from '../agreement/AgreementStore';
import FeatureStore from '../feature/FeatureStore';
import CustomerAccessStore from '../customerAccess/CustomerAccessStore';
import ProductUserStore from '../productUser/ProductUserStore';
import MandateCheckerStore from '../mandateChecker/MandateCheckerStore';
import RouteProps from '../../model/navigation/RouteProps';
import { ProductStoreV2 } from '../product/ProductStoreV2';

export interface RootStoreInitData {
  routes: RouteProps[];
}

export interface IRootStore extends CommonStore {
  agreementStore: any;
  cookieConsentStore: any;
  crmStore: any;
  customerAccessStore: any;
  deliveryAddressStore: any;
  errorStore: any;
  mandateCheckerStore: any;
  marketSegmentStore: any;
  megamenuStore: any;
  onlineHelpStore: any;
  productStore: any;
  productStoreV2: ProductStoreV2;
  productUserStore: any;
}

export class RootStore implements IRootStore {
  static async createRootStore(initData: RootStoreInitData) {
    const stores: IRootStore = {} as IRootStore;

    stores.errorStore = new ErrorStore();
    stores.featureStore = new FeatureStore();

    const featureStoreOnError = (error: any) => {
      stores.errorStore.setGeneralTechnicalError(error);
    };
    await stores.featureStore.init(featureStoreOnError);

    stores.cookieConsentStore = new CookieConsentStore();
    stores.navigationStore = new NavigationStore(initData.routes);
    stores.localeStore = new LocaleStore(stores);
    stores.translateStore = new TranslateStore(stores);

    stores.ssoStore = new SsoStore(stores);
    stores.crmStore = new CrmStore(stores);
    stores.mandateCheckerStore = new MandateCheckerStore(stores);

    await Promise.all([stores.localeStore.init(), stores.navigationStore.init(), stores.ssoStore.init(), stores.translateStore.init()]);

    stores.messageStore = new MessageStore();
    stores.spinnerStore = new SpinnerStore();
    stores.deliveryAddressStore = new AddressStore(stores);
    stores.onlineHelpStore = new OnlineHelpStore(stores);
    stores.productStore = new ProductStore(stores);
    stores.productStoreV2 = new ProductStoreV2({ ssoStore: stores.ssoStore });
    stores.productUserStore = new ProductUserStore(stores);
    stores.viewPortStore = new ViewPortStore();
    stores.megamenuStore = new MegamenuStore();
    stores.customerAccessStore = new CustomerAccessStore(stores);
    stores.agreementStore = new AgreementStore(stores);

    await Promise.all([stores.onlineHelpStore.init(), stores.productUserStore.init(), stores.customerAccessStore.init()]);

    return new RootStore(stores);
  }

  get cookieConsentStore() {
    return this.stores.cookieConsentStore;
  }

  get ssoStore() {
    return this.stores.ssoStore;
  }

  get messageStore() {
    return this.stores.messageStore;
  }

  get localeStore() {
    return this.stores.localeStore;
  }

  get translateStore() {
    return this.stores.translateStore;
  }

  get errorStore() {
    return this.stores.errorStore;
  }

  get crmStore() {
    return this.stores.crmStore;
  }

  get deliveryAddressStore() {
    return this.stores.deliveryAddressStore;
  }

  get spinnerStore() {
    return this.stores.spinnerStore;
  }

  get featureStore() {
    return this.stores.featureStore;
  }

  get navigationStore() {
    return this.stores.navigationStore;
  }

  get mandateCheckerStore() {
    return this.stores.mandateCheckerStore;
  }

  get onlineHelpStore() {
    return this.stores.onlineHelpStore;
  }

  get productStore() {
    return this.stores.productStore;
  }

  get productStoreV2() {
    return this.stores.productStoreV2;
  }

  get productUserStore() {
    return this.stores.productUserStore;
  }

  get viewPortStore() {
    return this.stores.viewPortStore;
  }

  get marketSegmentStore() {
    return this.stores.marketSegmentStore;
  }

  get megamenuStore() {
    return this.stores.megamenuStore;
  }

  get customerAccessStore() {
    return this.stores.customerAccessStore;
  }

  get agreementStore() {
    return this.stores.agreementStore;
  }

  private constructor(public readonly stores: IRootStore) {}

  async dataReset() {
    this.deliveryAddressStore.reset();
    this.crmStore.reset();
    this.errorStore.reset();
    this.ssoStore.reset();
  }
}

/**
 * In development mode we need to reference the rootStore from global window singleton to preserve
 * app state through hot-module-replacement operation.
 */
export default async (initData: RootStoreInitData): Promise<RootStore> => {
  if (process.env.NODE_ENV === 'development') {
    window.rootStore = window.rootStore || (await RootStore.createRootStore(initData));
    return window.rootStore;
  }
  // @ts-ignore
  return RootStore.createRootStore(initData);
};
