import {
  action,
  autorun,
  comparer,
  computed,
  IObservableArray,
  IReactionDisposer,
  Lambda,
  makeObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import {
  ActionCode,
  CategoryCode,
  CharacteristicCode,
  PermissionLevel,
  ProductDetailsPriceResource,
  ProductPriceResource,
  ProductSpecIdTvChannelsMap,
} from '@teliaee/sf.sdk.common';
import {
  AllowedActionResource,
  CustomerProductCharacteristicResource,
  CustomerProductDiagnosticsInfoAttributes,
  CustomerProductResource,
  CustomerProductRootCategoryCode,
  customerProductRootCategoryCodes,
  getCustomerProductTypeByRootCategory,
  NoticeResource,
  ProductAllowedAction,
  ProductDetailsConstraints,
  ProductDevice,
  ProductDeviceResource,
  ProductPrice,
  ProductTermResource,
  RoamingCountryResource,
  ServiceFeeResource,
  UsageConsumptionResource,
  UsageResource,
} from '@teliaee/sf.sdk.customer-products';
import CustomerProductCharacteristic, { CharacteristicValue } from './Characteristic';
import { isTemplate, Template } from './Template';
import {
  CustomerProductSummary,
  DataAmount,
  DataUsage,
  PhoneUsage,
  VoiceUsage,
  RoamingDataUsage,
} from '@teliaee/sf.service.customer-products';
import { SmsUsage } from './SmsUsage';
import { isApiResponseWithData, isApiResponseWithError, isCancelledResponse } from '@teliaee/sf.core';
import { OrderStatuses } from './OrderStatuses';
import { Api, loadProductOverviewData, readTvChannelsByProductSpec } from '../../service/Api';
import { ApiRequestConfig } from '../../service/ApiRequest';
import { DataUsageType } from './usage/model';
import { ProductOverviewData } from '../product/details/overview/ProductOverviewData';

type Locale = 'et' | 'ru' | 'en';

interface TvChannelsRequestTriggers {
  locale: Locale;
  isRootTvProduct: boolean;
  isDetailsLoading: boolean;
  isDetailsLoaded: boolean;
  productSpecIdsForTvChannels: number[];
}

export class CustomerProduct extends CustomerProductSummary {
  private detailsRequest = Api.productDetails();
  orderStatuses: OrderStatuses;
  @observable private isLoadInitiated = false;
  @observable private hasHadTimeToRenderDetails = false;
  @observable parent?: CustomerProduct;
  @observable children: IObservableArray<CustomerProduct> = observable([]);
  @observable customSolutionAgreementNumber = '';
  @observable private locale: Locale;
  @observable isTvChannelsRequestFailed = false;
  @observable tvChannelsMap: ProductSpecIdTvChannelsMap = {};
  @observable.shallow summary: CustomerProductResource;
  @observable isUserDataUpdated = false;
  private reactionDisposers: Map<string, IReactionDisposer | Lambda> = new Map();
  private requestCancellers: Map<string, Lambda> = new Map();
  @observable isProductDetailsDataLoading = false;
  @observable private isDetailsDataLoadInitiated = false;
  @observable private detailsData?: ProductOverviewData;
  @observable private isDetailsDataErrored = false;
  private detailsDataCancelRequest: () => void | undefined;

  private static hasUsage(data: UsageResource): boolean {
    return !!data && Number.isInteger(data.used);
  }

  constructor(summary: CustomerProductResource, parent?: CustomerProduct) {
    super(summary);
    makeObservable(this);
    if (parent) {
      this.parent = parent;
    } else if (summary.parentProductId && summary.parentProductOrigin) {
      this.parent = new CustomerProduct({
        productId: summary.parentProductId,
        origin: summary.parentProductOrigin,
      } as CustomerProductResource);
    }
    this.summary = summary;
    this.orderStatuses = new OrderStatuses(this.productId, this.isMobileGroup ? undefined : this.communicationNumber);
    this.reactionDisposers.set(
      'renderDelay',
      when(
        () => this.isDetailsLoaded,
        () => window.setTimeout(this.setHasHadTimeToRenderDetails, 500)
      )
    );
    this.reactionDisposers.set(
      'loadPrivateBillDetails',
      autorun(() => {
        if (this.privateBillProduct) {
          this.privateBillProduct.loadDetails();
        }
      })
    );
    this.reactionDisposers.set(
      'loadTvChannels',
      reaction<TvChannelsRequestTriggers>(
        () => ({
          locale: this.locale,
          isRootTvProduct: this.isRootTvProduct,
          isDetailsLoading: this.isDetailsLoading,
          isDetailsLoaded: this.isDetailsLoaded,
          productSpecIdsForTvChannels: this.productSpecIdsForTvChannels,
        }),
        this.loadTvChannels,
        { equals: comparer.structural }
      )
    );
  }

  @computed
  get details() {
    return this.detailsRequest.data;
  }

  @computed
  get isDetailsPending(): boolean {
    return this.isLoadInitiated && !this.hasHadTimeToRenderDetails;
  }

  @computed
  get isDetailsReady(): boolean {
    return this.isDetailsLoaded && this.hasHadTimeToRenderDetails;
  }

  @computed
  get isDetailsLoading(): boolean {
    const { isLoading } = this.detailsRequest;

    if (!this.isMobileGroup) {
      return isLoading;
    }

    return isLoading || this.children.some((child) => child.isDetailsLoading);
  }

  @computed
  get isDetailsLoaded(): boolean {
    const { isLoaded } = this.detailsRequest;

    if (!this.isMobileGroup) {
      return isLoaded;
    }

    return isLoaded && this.children.every((child) => child.isDetailsLoaded);
  }

  @computed
  get isDetailsErrored(): boolean {
    const { isErrored } = this.detailsRequest;

    if (!this.isMobileGroup) {
      return isErrored;
    }

    return isErrored || this.children.some((child) => child.isDetailsErrored);
  }

  // TODO loogika kui detailide ploki info ka laetud saab
  @computed
  get isDetailDataLoaded(): boolean {
    if (!this.isMobileGroup) {
      return this.isProductDetailsDataLoading;
    }
    return this.isProductDetailsDataLoading && this.children.every((child) => child.isDetailsLoaded);
  }

  @computed
  get template(): Template {
    if (this.isRoot && this.isClosed) {
      return Template.CLOSED_PRODUCT;
    }

    if (this.isMobileNumber) {
      return Template.MOBILE_STANDALONE_NUMBER;
    }

    if (this.summary?.template && isTemplate(this.summary.template)) {
      return this.summary.template;
    }

    if (this.isRoot) {
      return Template.PRODUCT_DEFAULT;
    }

    return this.primaryChild ? Template.PRIMARY_CHILD_DEFAULT : Template.SECONDARY_CHILD_DEFAULT;
  }

  get isInRootCategory(): boolean {
    return this.isInCategory(...customerProductRootCategoryCodes);
  }

  @computed
  get isRoot(): boolean {
    return !this.parent;
  }

  get root(): CustomerProduct | undefined {
    return [...this.ancestors].pop();
  }

  get ancestors(): CustomerProduct[] {
    const ancestors: CustomerProduct[] = [];
    let product = this.parent;

    while (product) {
      ancestors.push(product);
      product = product.parent;
    }

    return ancestors;
  }

  @computed
  get type() {
    return this.categoryCode && getCustomerProductTypeByRootCategory(this.categoryCode as CustomerProductRootCategoryCode);
  }

  @computed
  get hasDisabledActions(): boolean {
    return this.orderStatuses.hasPendingOrders || this.isDetailsLoading;
  }

  @computed
  get isRoamingEnabled(): boolean {
    return !!this.details && this.children.some((child) => 'GRV' === child.productOfferingCode);
  }

  @computed
  get privateBillProduct(): CustomerProduct | null {
    const { catalogCode } = this;
    const resource = this.details?.privateBillProduct?.data;
    return resource ? new CustomerProduct({ ...resource, catalogCode }) : null;
  }

  @computed
  get serviceFee(): ServiceFeeResource | null {
    return this.details?.serviceFee?.data ? this.details?.serviceFee?.data : null;
  }

  get isTotalServiceFeeErrored(): boolean {
    return !!this.details && isApiResponseWithError(this.details.serviceFee);
  }

  @computed
  get devices(): ProductDevice[] {
    return this.details?.devices?.data
      ? this.details?.devices?.data.map((resource: ProductDeviceResource) => new ProductDevice(resource))
      : [];
  }

  @computed
  get hasDevices(): boolean {
    return !this.areDevicesErrored && !!this.devices.length;
  }

  get areDevicesErrored(): boolean {
    return !!this.details && isApiResponseWithError(this.details.devices);
  }

  get primaryChildren(): CustomerProduct[] {
    return this.children.filter((child) => child.primaryChild);
  }

  get secondaryChildren(): CustomerProduct[] {
    return this.children.filter((child) => !child.primaryChild);
  }

  getChildrenInCategory(...categoryCodes: ArrayOfAtLeastOne<CategoryCode>): CustomerProduct[] {
    return this.children.filter((child) => child.isInCategory(...categoryCodes));
  }

  get characteristics(): CustomerProductCharacteristic[] {
    return this.summaryCharacteristics.concat(this.detailCharacteristics);
  }

  @computed
  private get summaryCharacteristics(): CustomerProductCharacteristic[] {
    return (this.summary.characteristics || []).map((resource) => new CustomerProductCharacteristic(resource));
  }

  @computed
  private get detailCharacteristics(): CustomerProductCharacteristic[] {
    const resources = this.details?.characteristics?.data;
    return resources
      ? resources.map((resource: CustomerProductCharacteristicResource | undefined) => new CustomerProductCharacteristic(resource))
      : [];
  }

  @computed
  get displayableCharacteristics(): CustomerProductCharacteristic[] {
    return this.detailCharacteristics.filter((characteristic) => characteristic.isDisplayable);
  }

  @computed
  get allowedActions(): ProductAllowedAction[] {
    const allowedActions = this.summaryAllowedActions.concat(this.detailAllowedActions);

    if (this.privateBillProduct) {
      [ActionCode.CHANGE_DATA_LIMIT].forEach((actionCode) => {
        const privateBillAction = this.privateBillActions.find((a) => a.action === actionCode);

        if (privateBillAction) {
          const existingIndex = allowedActions.findIndex((a) => a.action === actionCode);

          if (existingIndex > -1) {
            allowedActions.splice(existingIndex, 1, privateBillAction);
          } else {
            allowedActions.push(privateBillAction);
          }
        }
      });
    }

    return allowedActions;
  }

  @computed
  private get privateBillActions(): ProductAllowedAction[] {
    return this.privateBillProduct
      ? this.privateBillProduct.summaryAllowedActions.concat(this.privateBillProduct.detailAllowedActions)
      : [];
  }

  @computed
  get summaryAllowedActions(): ProductAllowedAction[] {
    return (this.summary.allowedActions || []).map((json) => new ProductAllowedAction(json));
  }

  @computed
  private get detailAllowedActions(): ProductAllowedAction[] {
    const resources = this.details?.allowedActions?.data;
    return resources ? resources.map((resource: AllowedActionResource) => new ProductAllowedAction(resource)) : [];
  }

  @computed
  get isDetailAllowedActionsErrored(): boolean {
    return isApiResponseWithError(this.details?.allowedActions?.errors);
  }

  @computed
  get detailNotices(): NoticeResource[] {
    const resources = this.details?.notices?.data;
    return resources || [];
  }

  @computed
  get notices(): NoticeResource[] {
    const noticeLabels: string[] = [];
    const allNotices = this.summaryNotices.concat(this.detailNotices, this.orderStatuses.orderNotices).filter(({ label }) => {
      const isDuplicate = noticeLabels.includes(label);
      noticeLabels.push(label);
      return !isDuplicate;
    });

    if (this.isUserDataUpdated) {
      allNotices.push({
        label: 'common.manage.user-data.success',
        type: 'success',
        onClose: () => this.setIsUserDataUpdated(false),
      });
    }

    return allNotices;
  }

  @computed
  private get detailPrices(): ProductPrice[] {
    const resources = this.details?.prices?.data;
    return resources ? resources.map((resource: ProductPriceResource | ProductDetailsPriceResource) => new ProductPrice(resource)) : [];
  }

  @computed
  get detailTerms(): ProductTermResource[] {
    const resources = this.details?.terms?.data;
    return resources || [];
  }

  @computed
  get hasTerms(): boolean {
    return this.detailTerms.length > 0;
  }

  get hasAppLinks(): boolean {
    return this.additionalAttributes ? Object.keys(this.additionalAttributes).includes('appLinks') : false;
  }

  get diagnosticsInfoAttributes(): CustomerProductDiagnosticsInfoAttributes | undefined {
    const { communicationNumber, owner, productId, categoryCode } = this;
    const diagnosticAction = this.getProductActions(ActionCode.DIAGNOSTICS).shift();

    if (!diagnosticAction || !communicationNumber || !owner) {
      return;
    }

    return {
      productId,
      communicationNumber,
      categoryCode,
      customerId: owner.customerId,
      external: diagnosticAction.external,
    };
  }

  get summaryFirstRecurringPrice(): ProductPrice | undefined {
    return this.summaryPrices.find((price) => price.isRecurring);
  }

  get firstRecurringPrice(): ProductPrice | undefined {
    return this.summaryFirstRecurringPrice || this.detailPrices.find((price) => price && price.isRecurring);
  }

  @computed
  get areDataUsagesErrored(): boolean {
    const dataUsagesErrored = !!this.details && isApiResponseWithError(this.details.dataUsages);

    return this.isMobileGroup ? dataUsagesErrored || !!this.children.find((child) => child.areDataUsagesErrored) : dataUsagesErrored;
  }

  @computed
  get areProductTermsErrored(): boolean {
    return !!this.details && isApiResponseWithError(this.details.terms);
  }

  @computed
  get numberDomesticDataUsage(): DataUsage | undefined {
    const data = this.usage?.numberDomesticDataUsage;
    return data && CustomerProduct.hasUsage(data) ? new DataUsage(data) : undefined;
  }

  @computed
  get groupDomesticDataUsage(): DataUsage | undefined {
    const data = this.children.find((child) => !!child.details)?.usage?.groupDomesticDataUsage;
    return data && CustomerProduct.hasUsage(data) ? new DataUsage(data) : undefined;
  }

  @computed
  get domesticDataUsage(): DataUsage | undefined {
    return this.isMobileGroup ? this.groupDomesticDataUsage : this.numberDomesticDataUsage;
  }

  @computed
  get domesticDataLimitValue(): string | undefined {
    return (
      this.getMobileCharacteristicValue(CharacteristicCode.DATA_LIMIT) ||
      this.privateBillProduct?.getMobileCharacteristicValue(CharacteristicCode.DATA_LIMIT)
    );
  }

  @computed
  get unknownDomesticDataUsage(): DataAmount | undefined {
    return this.getUnknownDataUsage('domestic');
  }

  @computed
  get numberEuDataUsage(): DataUsage | undefined {
    const data = this.usage?.numberEuDataUsage;
    return data && CustomerProduct.hasUsage(data) ? new DataUsage(data) : undefined;
  }

  @computed
  get groupEuDataUsage(): DataUsage | undefined {
    const data = this.children.find((child) => !!child.details)?.details?.usageConsumptions.data?.groupEuDataUsage;
    return data && CustomerProduct.hasUsage(data) ? new DataUsage(data) : undefined;
  }

  @computed
  get euDataUsage(): DataUsage | undefined {
    return this.isMobileGroup ? this.groupEuDataUsage : this.numberEuDataUsage;
  }

  @computed
  get euDataLimitValue(): string | undefined {
    return (
      this.getMobileCharacteristicValue(CharacteristicCode.DATA_FUP_LIMIT) ||
      this.privateBillProduct?.getMobileCharacteristicValue(CharacteristicCode.DATA_FUP_LIMIT)
    );
  }

  @computed
  get unknownEuDataUsage(): DataAmount | undefined {
    return this.getUnknownDataUsage('eu');
  }

  @computed
  get areVoiceAndSmsUsagesErrored(): boolean {
    return !!this.details && isApiResponseWithError(this.details.voiceAndSmsUsages);
  }

  @computed
  get voiceUsages(): PhoneUsage[] | undefined {
    const data = this.usage?.voiceUsages;
    return data?.map((voiceUsage) => new VoiceUsage(voiceUsage));
  }

  @computed
  get smsUsages(): PhoneUsage[] | undefined {
    const data = this.usage?.smsUsages;
    return data?.map((usage) => new SmsUsage(usage));
  }

  @computed
  get roamingCountry(): RoamingCountryResource | undefined {
    return this.usage?.roamingUsages?.country.data;
  }

  @computed
  get roamingUsage(): RoamingDataUsage | undefined {
    const data = this.usage?.roamingUsages;
    const country = this.roamingCountry?.code || '';
    const usage = (data?.usages || {})[country];
    return (usage && new RoamingDataUsage(usage)) || undefined;
  }

  @computed
  get hasInternationalCoverage(): boolean | undefined {
    if (!this.details) {
      return undefined;
    }
    const characteristicValue = this.getCharacteristicValue(CharacteristicCode.INTERNATIONAL_COVERAGE);
    return characteristicValue === 'Y';
  }

  get permissionLevel(): PermissionLevel | null {
    return this.summary.permission?.level || null;
  }

  @computed
  get supportsElektrileviNetwork(): boolean {
    return (
      this.getCharacteristicValue(CharacteristicCode.OWNER) === CharacteristicValue.NETWORK_OWNER.ELEKTRILEVI ||
      this.getCharacteristicValue(CharacteristicCode.NETWORK_OWNER) === CharacteristicValue.NETWORK_OWNER.ELEKTRILEVI
    );
  }

  @computed
  get supports5GNetwork(): boolean {
    const has5GCharacteristic = this.getCharacteristicValue(CharacteristicCode.TECHNOLOGY) === CharacteristicValue.TECHNOLOGY.NETWORK_5G;

    return has5GCharacteristic || this.has5GOfferingCode;
  }

  @computed
  get supports4GNetwork(): boolean {
    return this.getCharacteristicValue(CharacteristicCode.TECHNOLOGY) === CharacteristicValue.TECHNOLOGY.LTE;
  }

  @computed
  private get has5GOfferingCode(): boolean {
    return (
      this.privateBillProduct?.has5GOfferingCode ||
      (this.isMobileGroup
        ? this.children.some((child) => child.has5GOfferingCode)
        : this.children.some((child) => child.productOfferingCode === 'GPRS5'))
    );
  }

  @computed
  private get usage(): UsageConsumptionResource | undefined {
    return this.details?.usageConsumptions.data;
  }

  get hasNoDetails(): boolean {
    return this.summary.template === Template.NO_DETAILS;
  }

  get isMobileGroupNumber(): boolean {
    return this.isMobileNumber && !this.isRoot;
  }

  get isMobileStandaloneNumber(): boolean {
    return this.isMobileNumber && this.isRoot;
  }

  @computed
  get areChildrenDetailsLoaded(): boolean {
    return this.children.every((child) => typeof child.details !== 'undefined');
  }

  get isChildDevice(): boolean {
    return this.isDevice && !this.isRoot;
  }

  get isPrivateBill(): boolean {
    return this.summaryHasCharacteristicWithValue(CharacteristicCode.SERVICE_TYPE_CODE, 'BIL');
  }

  @computed
  private get isSharedCallsMobilePackage(): boolean {
    const offeringCodes = ['AK59', 'P10E', 'MEIE', 'P10P'];
    return offeringCodes.includes(this.productOfferingCode);
  }

  get shouldDisplayPrice() {
    return !this.isSharedCallsMobilePackage;
  }

  get isRootTvProduct(): boolean {
    return this.isTvProduct && this.isRoot;
  }

  get isAliasEditable(): boolean {
    return this.hasAction(ActionCode.CHANGE_ALIAS);
  }

  @computed
  get strictDataLimitValue(): CharacteristicValue.STRICT | undefined {
    return this.getCharacteristicValue(CharacteristicCode.STRICT) as CharacteristicValue.STRICT;
  }

  @computed
  private get hasNoDataLimit(): boolean {
    return this.strictDataLimitValue === CharacteristicValue.STRICT.UNLIMITED;
  }

  @computed
  get isPackageUnlimited(): boolean {
    return this.hasNoDataLimit || !!this.privateBillProduct?.hasNoDataLimit;
  }

  @computed
  get isDataUnlimited(): boolean {
    return this.isMobileMicroPackage ? !!this.getChildrenByOfferingCode('ADATA')[0]?.hasNoDataLimit : this.isPackageUnlimited;
  }

  @computed
  get isMobileExtraOrderAllowed(): boolean {
    return this.getChildrenByOfferingCode('SHMSG')[0]?.getCharacteristicValue(CharacteristicCode.CHANGE) !== 'EI';
  }

  @computed
  get hasParent(): boolean {
    return this.isDetailsReady && !!this.parent;
  }

  @computed
  get productDetailsData(): ProductOverviewData | undefined {
    return this.detailsData;
  }

  @computed
  get isProductDetailsDataErrored(): boolean {
    return this.isDetailsDataErrored;
  }

  @action
  setIsUserDataUpdated(isUserDataUpdated: boolean) {
    this.isUserDataUpdated = isUserDataUpdated;
  }

  @action
  private setHasHadTimeToRenderDetails = (): void => {
    this.hasHadTimeToRenderDetails = true;
  };

  @action
  async loadDetails() {
    if (this.hasLoadableDetails && !this.isLoadInitiated) {
      this.isLoadInitiated = true;
      await this.loadDetailsInternal();
    }
  }

  @action
  async loadDetailsData(pageId: string) {
    if (this.hasLoadableDetails && !this.isDetailsDataLoadInitiated) {
      this.isDetailsDataLoadInitiated = true;
      await this.loadDetailsDataInternal(pageId);
    }
  }

  @action
  setDetailsData(productDetails?: ProductOverviewData) {
    this.detailsData = productDetails;
  }

  @action
  async loadDetailsDataInternal(pageId: string): Promise<void> {
    this.isProductDetailsDataLoading = true;
    this.isDetailsDataLoadInitiated = true;
    const requestParams = {
      pageId,
    };
    const { responsePromise, cancelRequest } = loadProductOverviewData(this.productIdAndOrigin, requestParams);
    this.detailsDataCancelRequest = cancelRequest;
    const response = await responsePromise;
    if (!isCancelledResponse(response)) {
      this.setDetailsData(response.data as ProductOverviewData);
    }
    if (isApiResponseWithError(response)) {
      this.isDetailsDataErrored = true;
    }
    this.isProductDetailsDataLoading = false;
  }

  @action
  private async loadDetailsInternal() {
    await this.detailsRequest.load(this.detailsRequestConfig);
    if (!this.isDetailsErrored) {
      this.setChildren();
      this.setOrderStatuses();
      this.setParent();
      this.setCustomSolutionAgreementNumber(this.details?.customSolutionAgreementNumber || '');

      if (this.isMobileGroup) {
        await Promise.all(this.children.map((child) => child.loadDetails()));
      }
    }
  }

  @action
  private setChildren() {
    const { catalogCode } = this;
    const children = this.details?.children?.data || [];
    this.children.forEach((child) => child.terminate());
    this.children.replace(children.map((child: CustomerProductResource) => new CustomerProduct({ ...child, catalogCode }, this)));
  }

  @action
  private setParent() {
    const parentProduct = this.details?.parentProduct?.data;
    if (!!parentProduct) {
      this.parent = new CustomerProduct({
        productId: parentProduct.productId,
        origin: parentProduct.origin,
      } as CustomerProductResource);
    }
  }

  private setOrderStatuses() {
    this.orderStatuses.setData(this.details?.pendingOrders?.data || []);
    this.waitForPendingOrdersToFinish();
  }

  private waitForPendingOrdersToFinish() {
    const disposerKey = 'reloadDetailsUponFinishedOrders';

    if (!this.reactionDisposers.has(disposerKey)) {
      this.reactionDisposers.set(
        disposerKey,
        when(
          () => this.orderStatuses.isAllOrdersFinished,
          () => {
            this.reloadDetails();
            this.reactionDisposers.delete(disposerKey);
          }
        )
      );
    }
  }

  @action
  async reloadDetails() {
    if (this.hasLoadableDetails) {
      await this.loadDetailsInternal();
    }
  }

  @action
  setAlias(alias: string) {
    this.summary.alias = alias;
  }

  @action
  setCustomSolutionAgreementNumber = (customSolutionAgreementNumber: string) => {
    this.customSolutionAgreementNumber = customSolutionAgreementNumber;
  };

  @action
  terminate() {
    this.orderStatuses.terminate();
    this.reactionDisposers.forEach((disposer) => disposer());
    this.reactionDisposers.clear();
    this.requestCancellers.forEach((cancel) => cancel());
    this.requestCancellers.clear();
    this.detailsDataCancelRequest && this.detailsDataCancelRequest();
  }

  findCharacteristicByCode = <T = any>(...codes: CharacteristicCode[]): CustomerProductCharacteristic<T> | undefined =>
    this.characteristics.find((characteristic) => codes.includes(characteristic.code));

  getCharacteristicValue = <T extends any = string>(...codes: CharacteristicCode[]): T | undefined =>
    this.findCharacteristicByCode<T>(...codes)?.value;

  private getMobileCharacteristicValue(code: CharacteristicCode): string | undefined {
    return this.isMobileGroup
      ? this.getCharacteristicValue(code)
      : this.children.map((child) => child.getCharacteristicValue(code)).find((characteristicValue) => characteristicValue);
  }

  private summaryHasCharacteristicWithValue(code: string, value: string): boolean {
    return this.summary.characteristics?.some((char) => char.code === code && char.value === value) || false;
  }

  hasAction = (code: ActionCode) => !!this.getProductActions(code).length;

  getProductActions = (...codes: ArrayOfAtLeastOne<ActionCode>): ProductAllowedAction[] =>
    this.allowedActions.filter((allowedAction) => codes.includes(allowedAction.action));

  getProductSummaryAction(code: ActionCode): ProductAllowedAction | undefined {
    return this.summaryAllowedActions.find((allowedAction) => allowedAction.action === code);
  }

  getProductSummaryActions(...codes: ArrayOfAtLeastOne<ActionCode>): ProductAllowedAction[] {
    return this.summaryAllowedActions.filter((allowedAction) => codes.includes(allowedAction.action));
  }

  getChildrenByOfferingCode(offeringCode: string): CustomerProduct[] {
    return this.children.filter((child) => child.productOfferingCode === offeringCode);
  }

  private getUnknownDataUsage(dataUsageType: DataUsageType): DataAmount | undefined {
    const dataUsage = dataUsageType === 'domestic' ? this.domesticDataUsage : this.euDataUsage;

    if (!dataUsage) {
      return undefined;
    }

    const totalMemberUsage = this.children.reduce((total, child) => total + this.getNumberUsage(child, dataUsageType), 0);

    const unknownUsage = dataUsage.used.bytes - totalMemberUsage;

    return unknownUsage > 0 ? new DataAmount(unknownUsage) : undefined;
  }

  private getNumberUsage = (product: CustomerProduct, dataUsageType: DataUsageType): number => {
    const numberDataUsage = dataUsageType === 'domestic' ? product.numberDomesticDataUsage : product.numberEuDataUsage;

    return numberDataUsage ? numberDataUsage.used.bytes : 0;
  };

  @computed
  private get detailsRequestConfig(): ApiRequestConfig {
    const { productId, catalogCode, origin } = this;
    const searchConstraints: ProductDetailsConstraints = { catalogCode, origin };

    return {
      urlParams: { productId },
      params: {
        searchConstraints: JSON.stringify(searchConstraints),
      },
    };
  }

  private get hasLoadableDetails(): boolean {
    return this.isMobileNumber || this.isMobilePrepaidCard || this.isPrivateBill || (this.isInRootCategory && !this.isChildDevice);
  }

  @action
  setLocale(locale: Locale) {
    this.locale = locale;
  }

  getTvChannelsByProductSpecId(productSpecId: number) {
    const tvChannelsMap = this.root?.tvChannelsMap || this.tvChannelsMap;
    return tvChannelsMap[productSpecId] || [];
  }

  @computed
  private get tvBasePackageChild() {
    return this.children.find((child) => child.isInCategory(CategoryCode.TV_BASE_PACKAGES));
  }

  @computed
  private get tvThemePackageChildren() {
    return this.children.filter((child) => child.isInCategory(CategoryCode.TV_THEME_PACKAGES));
  }

  @computed
  private get tvExtraChannelChildren() {
    return this.children.filter((child) => child.isInCategory(CategoryCode.TV_EXTRA_CHANNELS));
  }

  @computed
  private get productSpecIdsForTvChannels(): number[] {
    return [this.tvBasePackageChild, ...this.tvThemePackageChildren, ...this.tvExtraChannelChildren].reduce(
      (productSpecIds, product) =>
        typeof product?.productSpecId === 'number' ? productSpecIds.concat(product.productSpecId) : productSpecIds,
      [] as number[]
    );
  }

  private loadTvChannels = async (triggers: TvChannelsRequestTriggers) => {
    const { locale, isRootTvProduct, isDetailsLoading, isDetailsLoaded, productSpecIdsForTvChannels } = triggers;

    if (!isRootTvProduct || !locale || isDetailsLoading || !isDetailsLoaded) {
      return;
    }

    const cancelPreviousRequest = this.requestCancellers.get('loadTvChannels');
    if (cancelPreviousRequest) {
      cancelPreviousRequest();
    }

    if (!productSpecIdsForTvChannels.length) {
      return;
    }

    this.setIsTvChannelsRequestFailed(false);

    const { responsePromise, cancelRequest } = readTvChannelsByProductSpec(productSpecIdsForTvChannels, locale);
    this.requestCancellers.set('loadTvChannels', cancelRequest);
    const response = await responsePromise;

    if (isApiResponseWithData(response)) {
      this.setTvChannelsMap(response.data);
    } else {
      this.setIsTvChannelsRequestFailed(true);
    }
  };

  @action
  private setTvChannelsMap(tvChannelsMap: ProductSpecIdTvChannelsMap) {
    this.tvChannelsMap = tvChannelsMap;
  }

  @action
  private setIsTvChannelsRequestFailed(isTvChannelsRequestFailed: boolean) {
    this.isTvChannelsRequestFailed = isTvChannelsRequestFailed;
  }
}

export default CustomerProduct;
