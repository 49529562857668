import { atobUTF8 } from '@teliaee/sf.core';

export interface HtmlDocumentResource {
  encodedHtml: string;
}

export class HtmlDocument {
  static fromJson(resource: HtmlDocumentResource) {
    return new HtmlDocument(resource);
  }

  constructor(private readonly resource: HtmlDocumentResource) {}

  get encodedHtml() {
    return this.resource.encodedHtml;
  }

  get htmlString() {
    return atobUTF8(this.encodedHtml, false);
  }
}
