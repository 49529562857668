import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ApiResponse, isApiResponseWithError } from '@teliaee/sf.core';
import { loadProductLists } from '../../service/Api';
import SsoStore from '../sso/SsoStore';
import { GlobalProductIdUtil } from '../../util/GlobalProductIdUtil';
import { Origin } from '@teliaee/sf.sdk.common';
import { ProductListPageData } from '../../model/product/list/ProductListPageData';
import { AccessData } from '../../model/AccessData';
import { ListFilterConstraints } from '../../model/product/list/Filter';
import { ListItemData } from '../../model/product/list/listItem/ListItemData';

export interface ProductListLoadParams {
  listName: string;
  page: number;
  pageId: string;
}

export interface ProductListPageDataExtended extends ProductListPageData {
  isLoading: boolean;
  isErrored: boolean;
  accessData: AccessData;
}

export type ApiResponseWithAccessData<T> = ApiResponse<T> & {
  accessData: AccessData;
};

export interface ProductStoreV2Context {
  ssoStore: SsoStore;
}

export class ProductStoreV2 {
  @observable
  private productListPageDataMap = observable.map<string, ProductListPageDataExtended>();

  @observable
  private filter: ListFilterConstraints | undefined = undefined;

  constructor(private readonly context: ProductStoreV2Context) {
    makeObservable(this);
    reaction(
      () => this.context.ssoStore.customerId,
      () => this.clear()
    );
  }

  @computed
  get products() {
    return Array.from(this.productListPageDataMap.values()).reduce((acc, data) => [...acc, ...data.items], [] as ListItemData[]);
  }

  findProduct(productIdAndOrigin: string) {
    return this.products.find((product) => this.matchProductByGlobalId(product, productIdAndOrigin));
  }

  getProductListPageData(listName: string): ProductListPageDataExtended | undefined {
    return this.productListPageDataMap.get(listName);
  }

  @computed
  get filterConstraints(): ListFilterConstraints | undefined {
    return this.filter;
  }

  @action
  setFilter(filter?: ListFilterConstraints) {
    this.filter = filter;
  }

  async loadProductListPageData(props: ProductListLoadParams) {
    const { listName, ...requestParams } = props;
    this.setIsLoading(listName, true);

    const { responsePromise } = loadProductLists(listName, requestParams);
    const response = await responsePromise;

    this.handleResponse(listName, response as ApiResponseWithAccessData<ProductListPageData>);

    this.setIsLoading(listName, false);
  }

  @action
  private handleResponse(listName: string, response: ApiResponseWithAccessData<ProductListPageData>) {
    const data = this.mapResponseToProductListPageDataExtended(response);
    const existingItemsList = this.productListPageDataMap.get(listName)?.items || [];

    const handledData = {
      ...data,
      items: [...existingItemsList, ...data.items],
    };

    this.productListPageDataMap.set(listName, handledData);
  }

  @action
  private setIsLoading(listName: string, isLoading: boolean) {
    const data = this.productListPageDataMap.get(listName);

    if (data) {
      const updatedData = { ...data, isLoading };
      this.productListPageDataMap.set(listName, updatedData);
    }
  }

  @action
  clear() {
    this.productListPageDataMap.clear();
  }

  private mapResponseToProductListPageDataExtended(response: ApiResponseWithAccessData<ProductListPageData>): ProductListPageDataExtended {
    const { accessData, data } = response;
    return {
      hasNextPage: data?.hasNextPage || false,
      items: data?.items || [],
      accessData,
      isErrored: isApiResponseWithError(response),
      isLoading: false,
      notice: data?.notice,
    };
  }

  private matchProductByGlobalId(product: ListItemData, productIdAndOrigin: string) {
    const { productData } = product;

    if (productData) {
      const { productId, origin } = productData;
      const globalProductId = GlobalProductIdUtil.getGlobalProductId(productId, origin as Origin);
      return globalProductId === productIdAndOrigin;
    }

    return false;
  }
}
