import React, { useContext } from 'react';
import { Card, CardContent, Container, Grid, GridColumn, Icon, Separator, Text, AnalyticsProvider } from 'telia-front-react';
import ErrorStore from '../../shared/store/error/ErrorStore';
import NavigationStore from '../../shared/store/navigation/NavigationStore';
import { Button } from '@teliaee/sf.ui.common';
import RootStoreContext from '../../shared/store/root/RootStoreContext';

export const TechnicalError = ({ message, category }: { message: string; category?: string }) => {
  const { translateStore, errorStore, navigationStore } = useContext(RootStoreContext);
  const reload = (): void => {
    window.onbeforeunload = null;
    errorStore.clearError(ErrorStore.level.TECHNICAL_ERROR);
    window.location.reload();
  };
  const navigateToHome = (): void => {
    window.onbeforeunload = null;
    errorStore.clearError(ErrorStore.level.TECHNICAL_ERROR);
    navigationStore.navigateTo(NavigationStore.SELF);
  };
  return (
    <AnalyticsProvider page={category} group="ERROR">
      <Container>
        <Separator spacing="small" color="invisible" />
        <Text className="text-center">
          <span className="text-error">
            <Icon modifiers="#alert" size="xxxl" />
          </span>
          <h1 dangerouslySetInnerHTML={translateStore.translateGlobalHtml('404.title')} />
          <p className="h4" dangerouslySetInnerHTML={translateStore.translateHtml(message)} />
        </Text>
        <Separator spacing="small" color="invisible" />
        <Card padding="medium">
          <CardContent>
            <Grid align={['between-sm', 'middle-xs']}>
              <GridColumn width={['xs-12', 'sm']} order={['last-sm']}>
                <div className="text-center">
                  <Button
                    id="technical-error-retry"
                    onClick={reload}
                    text={translateStore.translate('error.backend.general.retry')}
                    gaLabel="Reload error modal"
                  />
                </div>
              </GridColumn>
              <GridColumn width={['xs-12', 'sm']}>
                <div className="text-center">
                  <Button
                    id="technical-error-back-to-home"
                    link
                    iconLeft="#arrow-left"
                    onClick={navigateToHome}
                    text={translateStore.translate('error.backend.general.goto.home')}
                    gaLabel="Back to home"
                  />
                </div>
              </GridColumn>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AnalyticsProvider>
  );
};
