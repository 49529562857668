import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Route, Router, Switch } from 'react-router-dom';
import { ModalContainer } from 'react-router-modal';
import { AnalyticsProvider, LabelProvider, Main, MainContent, Overlay, THeaderOpenSection, ThemeProvider } from 'telia-front-react';
import { AppFooter, AppHeader } from 'telia-megamenu';
import { Analytics, CoreConfig } from '@teliaee/sf.core';
import { GeneralTechnicalError } from './general/technicalError/GeneralTechnicalError';
import SpinnerToggle from './shared/components/spinner/SpinnerToggle';
import { FULL_PAGE } from './shared/components/spinner/SpinnerStore';
import { Loadable } from './shared/util/Loadable';
import { ErrorBoundary } from './general/technicalError/ErrorBoundary';
import { Head, AuthRedirect, CommonStoreContext, NotFound } from '@teliaee/sf.ui.common';
import { CommonStore } from '@teliaee/sf.service.common';
import { Route as customerRoute } from './shared/routes/Route';
import FlowComponentWrapper from './general/flowComponents/FlowComponentWrapper';
import { CustomerProductsRoot } from './general/customerProducts/CustomerProductsRoot';
import createRootStore, { RootStore } from './shared/store/root/RootStore';
import RootStoreContext from './shared/store/root/RootStoreContext';
import { routes } from './general/routes/routes';
import RouteProps from './shared/model/navigation/RouteProps';

/* istanbul ignore next 6 */
export const getIconPath = (name: string) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const req = require('@telia-front/icon/lib/svg/icons.svg');
  return req + name;
};

const overlay = <Overlay spinner />;

@observer
export default class App extends React.Component {
  private static META_TAGS = [];

  private loadableRootStore = Loadable.fromLoad(() => createRootStore({ routes: routes as unknown as RouteProps[] }));

  /* istanbul ignore next 3 */
  private static renderCustomerProducts = () => (
    <CustomerProductsRoot environment={CoreConfig.environment} routes={routes as unknown as customerRoute[]} />
  );

  static renderApp = (rootStore: RootStore, commonStore: CommonStore) => {
    const { ssoStore, localeStore, errorStore, navigationStore, translateStore, megamenuStore } = rootStore;
    const { environment, isBrowser } = CoreConfig;
    const headerFooterCommonProps = {
      app: 'selfservice',
      /* istanbul ignore next */
      tier: environment === 'local' ? 'dev' : environment,
      lang: localeStore.currentLocale,
    };

    /* istanbul ignore next 5 */
    const handleHeaderSectionChange = (openSection: THeaderOpenSection) => {
      if (openSection !== 'sso') {
        rootStore.ssoStore.dismissSignIn();
      }

      megamenuStore.setOpenSection(openSection);
    };

    return (
      <LabelProvider labels={translateStore.translations.global}>
        <RootStoreContext.Provider value={rootStore}>
          <CommonStoreContext.Provider value={commonStore}>
            <ThemeProvider getIconPath={getIconPath}>
              <AnalyticsProvider
                event={Analytics.defaultEvent}
                page={Analytics.appName}
                group={Analytics.defaultCategory}
                onPush={Analytics.push}
              >
                <Provider {...rootStore.stores} rootStore={rootStore}>
                  <FlowComponentWrapper
                    translateStore={rootStore.translateStore}
                    ssoStore={rootStore.ssoStore}
                    localeStore={rootStore.localeStore}
                  >
                    <>
                      <Head meta={App.META_TAGS} />
                      <Router history={rootStore.navigationStore.history}>
                        <Main
                          consentEnabled={
                            /* istanbul ignore next */ rootStore.featureStore.enableNewCookieBot
                              ? rootStore.cookieConsentStore.isCookiebotEnabled
                              : undefined
                          }
                          consent={
                            /* istanbul ignore next */ rootStore.featureStore.enableNewCookieBot
                              ? { ...rootStore.cookieConsentStore.cookieConsents }
                              : undefined
                          }
                        >
                          <SpinnerToggle name={FULL_PAGE}>
                            <Overlay spinner type="fixed" />
                          </SpinnerToggle>
                          <>
                            {(ssoStore.loaded || ssoStore.loadIsSlow) && (
                              <>
                                {isBrowser && (
                                  <AppHeader
                                    {...headerFooterCommonProps}
                                    skipSsoModule
                                    isSPA
                                    onPathChange={navigationStore.onPathChange}
                                    currentPath={navigationStore.location.pathname}
                                    ssoClientState={ssoStore.state}
                                    customerType={ssoStore.contextOrCustomerSegment}
                                    ssoClient={ssoStore.ssoClient}
                                    loginOptions={ssoStore.ssoModalProps}
                                    onLangChange={localeStore.setLocale}
                                    onClientStateChange={ssoStore.setState}
                                    showEnLang
                                    openSection={megamenuStore.openSection}
                                    onSectionChange={handleHeaderSectionChange}
                                  />
                                )}
                                <MainContent>
                                  <ErrorBoundary>
                                    {errorStore.generalTechnicalError ? <GeneralTechnicalError /> : App.renderMainView()}
                                  </ErrorBoundary>
                                </MainContent>
                                {isBrowser && <AppFooter {...headerFooterCommonProps} onPathChange={navigationStore.navigateTo} />}
                              </>
                            )}
                          </>
                        </Main>
                      </Router>
                    </>
                  </FlowComponentWrapper>
                </Provider>
              </AnalyticsProvider>
            </ThemeProvider>
          </CommonStoreContext.Provider>
        </RootStoreContext.Provider>
      </LabelProvider>
    );
  };

  private static renderMainView(): JSX.Element {
    return (
      <AuthRedirect>
        <React.Suspense fallback={overlay}>
          <Switch>
            <Route exact path={routes.flatMap((route) => route.path)} render={App.renderCustomerProducts} />
            <Route component={NotFound} />
          </Switch>
          <ModalContainer />
        </React.Suspense>
      </AuthRedirect>
    );
  }

  render() {
    const rootStore = this.loadableRootStore.data;

    if (rootStore && rootStore.ssoStore.loaded) {
      return App.renderApp(rootStore, {
        ssoStore: rootStore.ssoStore,
        messageStore: rootStore.messageStore,
        localeStore: rootStore.localeStore,
        translateStore: rootStore.translateStore,
        spinnerStore: rootStore.spinnerStore,
        navigationStore: rootStore.navigationStore,
        featureStore: rootStore.featureStore,
        viewPortStore: rootStore.viewPortStore,
        mandateCheckerStore: rootStore.mandateCheckerStore,
      });
    }

    return (
      <Main>
        <Overlay spinner color="light" type="fixed" />
      </Main>
    );
  }
}
