import { lazy } from 'react';
import { match as Match } from 'react-router-dom';
import { ErrorUtil } from '@teliaee/sf.core';
import { ProductRef } from '@teliaee/sf.sdk.common';
import { HeadProps } from '@teliaee/sf.ui.common';
import { GlobalProductIdUtil } from '../util/GlobalProductIdUtil';

export interface RouteOptions {
  key: string;
  path: string | string[];
  head?: HeadProps;
}
export interface CustomerProductsRouteComponentProps<Params extends { [K in keyof Params]?: string } = {}> {
  match: Match<Params>;
}
export type CustomerProductsRouteComponentType = React.ComponentType<CustomerProductsRouteComponentProps<any>>;

export class Route {
  private options: RouteOptions;
  readonly component = lazy<CustomerProductsRouteComponentType>(this.componentFactory);

  constructor(private componentFactory: () => Promise<{ default: CustomerProductsRouteComponentType }>, options: RouteOptions) {
    this.options = { ...options };
  }

  get key(): string {
    return this.options.key;
  }

  get path(): string | string[] {
    return this.options.path;
  }

  get head(): HeadProps | undefined {
    return this.options.head;
  }

  // TODO: probably needs to be refined in the future since ELIS is not supported common product classes
  validate(match: Match<{ productIdAndOrigin?: string }>): boolean {
    const { productIdAndOrigin } = match.params;

    if (!productIdAndOrigin) {
      return true;
    }

    const origin = GlobalProductIdUtil.getOrigin(productIdAndOrigin);

    if (origin === 'ELIS') {
      return true;
    }

    try {
      ProductRef.fromProductIdAndOrigin(productIdAndOrigin);
      return true;
    } catch (error: any) {
      ErrorUtil.pushError(error);
      return false;
    }
  }
}
