import { ProductRef, ProductReference } from '@teliaee/sf.sdk.common';

export interface ProductUserCompactResource extends ProductReference {
  customerId: number;
}

export default class ProductUserCompact extends ProductRef implements ProductUserCompactResource {
  customerId: number;

  constructor(data: ProductUserCompactResource) {
    super(data);
    Object.assign(this, data);
  }
}
