import { PhoneUsageResource } from '@teliaee/sf.sdk.customer-products';
import { PhoneUsage } from '@teliaee/sf.service.customer-products';

export class SmsUsage extends PhoneUsage {
  used = this.resource.used;
  remaining = this.resource.remaining;
  limit = this.resource.limit;

  constructor(resource: PhoneUsageResource) {
    super(resource, resource.includesTariffClasses);
  }
}
